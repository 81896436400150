import React from "react";
import { CarItemTypes } from "../../types/CarItemTypes";

import "./CarItem.scss";
import { setBookingLocalValue } from "../../lib/helpers/bookingLocalStorage";
import { useHistory } from "react-router-dom";

interface Props {
  data?: CarItemTypes;
  isLoading?: boolean;
}

export const CarItem = (props: Props) => {
  const history = useHistory();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const clearOfferData = {
      selectedOffer: props.data,
    };
    setBookingLocalValue(clearOfferData);
    history.push("/reservation/driverdetails");
  };
  return (
    <div className="CarItem">
      <figure className="CarItem__image">
        {props.data?.image && (
          <img
            src={process.env.REACT_APP_URL + props.data?.image}
            alt={`${props.data?.model?.brand?.name} ${props.data?.model?.name}`}
          />
        )}
      </figure>
      <h3 className="CarItem__name">
        {props.data?.model?.brand?.name} <span>{props.data?.model?.name}</span>
      </h3>
      <span className="CarItem__price">
        Total <strong>{props.data?.price}€</strong>
      </span>
      <div className="CarItem__details">
        <span>{props.data?.type?.name}</span>
        <span>{props.data?.year}</span>
        <span>{props.data?.transmission.name}</span>
        <span>{props.data?.fuel.name}</span>
      </div>
      <button className="CarItem__button" onClick={handleSubmit}>
        Book Now
      </button>
    </div>
  );
};
