import React from "react";

import { Header } from "./layouts/Header/Header";
import { Routes } from "./routes/Routes";

import "./App.scss";
import { Logo } from "./components/Logo/Logo";
import { useAuthContext } from "./lib/context/AuthContext/AuthContext";

function App() {
  const authCtx = useAuthContext();
  if (authCtx.isLoading) {
    return (
      <div className="App App__loading">
        <Logo noPath />
      </div>
    );
  }
  return (
    <div className="App">
      <Header />
      <main className="App__main">
        <Routes />
      </main>
    </div>
  );
}

export default App;
