import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { NavLink } from "react-router-dom";

import "./TermAndConditions.scss";

interface TermAndConditionsProps {
  onChange: (value: boolean) => void;
  checked: boolean;
  hasError: boolean | undefined;
  errorMessage: string | undefined;
}

export const TermAndConditions = (props: TermAndConditionsProps) => {
  return (
    <FormGroup check className="agreement mt-4 TermAndConditions">
      <Label for="termsAndConditions" check={props.checked}>
        <Input
          type="checkbox"
          name="termsAndConditions"
          id="termsAndConditions"
          onChange={(v) => props.onChange(v.target.checked)}
          invalid={props.hasError}
        />
        Pajtohem me
        <NavLink to="/terms-conditions">Kushtet e blerjes</NavLink>
        <FormFeedback>{props.errorMessage}</FormFeedback>
      </Label>
    </FormGroup>
  );
};
