import { RouteProps } from "react-router-dom";

import { Home } from "../pages/Home/Home";
import { Login } from "../pages/Login/Login";
import { Register } from "../pages/Register/Register";
import { Reservation } from "../pages/Reservation/Reservation";
import { UserDetails } from "../pages/UserDetails/UserDetails";
import { ReservationConfirm } from "../pages/ReservationConfirm/ReservationConfirm";

export enum RouteType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

interface AppRoute extends RouteProps {
  type?: RouteType;
}

export const AppRoutes: AppRoute[] = [
  // Public Routes
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "login",
    component: Login,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "register",
    component: Register,
  },

  // {
  //   type: RouteType.PUBLIC,
  //   exact: true,
  //   path: "forgot-password",
  //   component: ForgotPassword,
  // },
  // {
  //   type: RouteType.PUBLIC,
  //   exact: true,
  //   path: "reset-password",
  //   component: ResetPassword,
  // },
  //
  // // Private Routes
  // {
  //   type: RouteType.PRIVATE,
  //   path: "profile",
  //   component: Profile,
  // },

  // Normal Routes
  {
    exact: true,
    path: "/",
    component: Home,
  },
  {
    exact: true,
    path: "reservation/freelist",
    component: Reservation,
  },
  {
    exact: true,
    path: "reservation/driverdetails",
    component: UserDetails,
  },
  {
    exact: true,
    path: "reservation/confirm",
    component: ReservationConfirm,
  },
];
