import { useFormik, FormikValues, FormikHelpers } from "formik";
import * as Yup from "yup";
import { RegisterFields } from "../../pages/Register/Register";

interface UseRegisterFormOptions {
  initialValues?: RegisterFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<RegisterFields>
  ) => Promise<any>;
}

export const useRegisterFormik = (options: UseRegisterFormOptions) => {
  const RegisterSchema = Yup.object().shape({
    registerFullName: Yup.string().required("Full name is required"),
    registerEmail: Yup.string()
      .email("Email is not valid")
      .required("Email is required"),
    registerAddress: Yup.string().required("Address is required"),
    registerPhone: Yup.string().required("Phone is required"),
    registerPassword: Yup.string()
      .min(5, "Password is not longer ")
      .required("Passowrd is required"),
    registerConfirmPassword: Yup.string().oneOf(
      [Yup.ref("registerPassword")],
      "Password not match"
    ),
    registerTermAndConditions: Yup.bool().oneOf(
      [true],
      "Please check terms and conditions"
    ),
  });

  return useFormik({
    initialValues: {
      registerFullName: "",
      registerEmail: "",
      registerPhone: "",
      registerAddress: "",
      registerPassword: "",
      registerConfirmPassword: "",
      registerTermAndConditions: false,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: RegisterSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type RegisterFormik = ReturnType<typeof useRegisterFormik>;
