import React from "react";
import { NavLink } from "react-router-dom";

import "./ReservationConfirm.scss";

export const ReservationConfirm = () => {
  return (
    <div className="ReservationConfirm">
      Reservation Confirm
      <NavLink to="/">Back to Home</NavLink>
    </div>
  );
};
