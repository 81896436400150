import React from "react";

import "./UserDetails.scss";
import { useBookingContext } from "../../lib/context/BookingContext/BookingContext";
import moment from "moment";
import { getBookingLocalValues } from "../../lib/helpers/bookingLocalStorage";
import { useBookingFormik } from "../../lib/formik/useBookingForkmik";
import { BookingForm } from "./BookingForm";
import { useHistory } from "react-router-dom";

export interface BookingFields {
  bookingFullName: string;
  bookingEmail: string;
  bookingPhone: string;
  bookingAddress: string;
  bookingImageID: any;
  bookingImagePassport: any;
  bookingImageLicense: any;
}

export const UserDetails = () => {
  const localBookingData = getBookingLocalValues();
  const bookingCtx = useBookingContext();
  const history = useHistory();

  const localPickupDateTime = moment(
    `${localBookingData.selectedPickupDate}` +
      ` ${localBookingData.selectedPickupTime}`,
    "DD/MM/YYYY hh:mm:ss"
  ).format("YYYY-MM-DD hh:mm");

  const localDropDateTime = moment(
    `${localBookingData.selectedDropDate}` +
      ` ${localBookingData.selectedDropTime}`,
    "DD/MM/YYYY hh:mm:ss"
  ).format("YYYY-MM-DD hh:mm");

  const formik = useBookingFormik({
    onSubmit: async (values) => {
      bookingCtx.createBooking({
        fullName: values.bookingFullName,
        email: values.bookingEmail,
        phone: values.bookingPhone,
        address: values.bookingAddress,
        pikcupDate: localPickupDateTime,
        dropDate: localDropDateTime,
        pikcupLocation: 3,
        dropLocation: 3,
        carID: localBookingData.selectedOffer.id,
        imageID: values.bookingImageID,
        imagePassport: values.bookingImagePassport,
        imageDriver: values.bookingImageLicense,
        price: localBookingData.selectedOffer.price,
      });

      history.push("/reservation/confirm");
    },
  });
  return (
    <div className="UserDetails">
      <div className="container">
        <h1>Please fill all your informations</h1>
        <BookingForm formik={formik} />
      </div>
    </div>
  );
};
