import React from "react";
import { Container, Row } from "reactstrap";

import { Logo } from "../../components/Logo/Logo";
import { MainNavigation } from "../../components/MainNavigation/MainNavigation";

import "./Header.scss";

export const Header = () => {
  return (
    <div className="Header">
      <Container>
        <Row>
          <Logo />
          <MainNavigation />
        </Row>
      </Container>
    </div>
  );
};
