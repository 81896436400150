import React, { useEffect } from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Form,
  FormFeedback,
} from "reactstrap";

import { RegisterFormik } from "../../lib/formik/useRegisterForkmik";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";

import { TermAndConditions } from "../../components/TermAndConditions/TermAndConditions";

interface Props {
  loading?: boolean;
  formik: RegisterFormik;
}

export const RegisterForm = (props: Props) => {
  const { formik } = props;
  const authCtx = useAuthContext();

  useEffect(() => {
    if (authCtx.registerError) {
      console.log("register error", authCtx.registerError);
      // notification.danger(authCtx.registerError, true);
    }
  }, [authCtx.registerError]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs="12" sm="6">
          <FormGroup className="form-group">
            <Label for="registerFullName">Full Name</Label>
            <Input
              type="text"
              id="registerFullName"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.registerFullName}
              invalid={
                !!formik.errors.registerFullName &&
                formik.touched.registerFullName
              }
            />
            <FormFeedback>{formik.errors.registerFullName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="registerEmail">Email</Label>
            <Input
              type="email"
              id="registerEmail"
              onChange={formik.handleChange}
              className="form-control-custom"
              value={formik.values.registerEmail}
              invalid={
                !!formik.errors.registerEmail && formik.touched.registerEmail
              }
            />
            <FormFeedback>{formik.errors.registerEmail}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="registerPhone">Phone</Label>
            <Input
              type="text"
              id="registerPhone"
              onChange={formik.handleChange}
              className="form-control-custom"
              value={formik.values.registerPhone}
              invalid={
                !!formik.errors.registerPhone && formik.touched.registerPhone
              }
            />
            <FormFeedback>{formik.errors.registerPhone}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="registerAddress">Address</Label>
            <Input
              type="text"
              id="registerAddress"
              onChange={formik.handleChange}
              className="form-control-custom"
              value={formik.values.registerAddress}
              invalid={
                !!formik.errors.registerAddress &&
                formik.touched.registerAddress
              }
            />
            <FormFeedback>{formik.errors.registerAddress}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="registerPassword">Password</Label>
            <Input
              type="password"
              id="registerPassword"
              onChange={formik.handleChange}
              className="form-control-custom"
              value={formik.values.registerPassword}
              invalid={
                !!formik.errors.registerPassword &&
                formik.touched.registerPassword
              }
            />
            <FormFeedback>{formik.errors.registerPassword}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="registerConfirmPassword">Confirm Password</Label>
            <Input
              type="password"
              id="registerConfirmPassword"
              onChange={formik.handleChange}
              className="form-control-custom"
              value={formik.values.registerConfirmPassword}
              invalid={
                !!formik.errors.registerConfirmPassword &&
                formik.touched.registerConfirmPassword
              }
            />
            <FormFeedback>{formik.errors.registerConfirmPassword}</FormFeedback>
          </FormGroup>
        </Col>

        <div className="w-100" />
        <Col xs="12" md="6">
          <TermAndConditions
            checked={formik.values.registerTermAndConditions}
            hasError={
              !!formik.errors.registerTermAndConditions &&
              formik.touched.registerTermAndConditions
            }
            errorMessage={formik.errors.registerTermAndConditions}
            onChange={(value: boolean) =>
              formik.setFieldValue("registerTermAndConditions", value)
            }
          />
        </Col>
        <Col xs="12">
          <FormGroup>
            <button
              type="submit"
              disabled={props.loading}
              onClick={() => props.formik.submitForm()}
              className="RegisterForm__btn w-100-mobile"
            >
              {props.loading ? "Registering" : "Register"}
            </button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
