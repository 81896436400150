import { apiRequest } from "./Api";

export const booking = (data: {
  address: any;
  pikcupLocation: any;
  imageID: any;
  fullName: any;
  dropLocation: any;
  dropDate: any;
  carID: any;
  pikcupDate: any;
  imageDriver: any;
  phone: any;
  price: any;
  imagePassport: any;
  email: any;
}) => {
  const formData = new FormData();
  formData.append("user[id]", "");
  formData.append("user[name]", data.fullName);
  formData.append("user[email]", data.email);
  formData.append("user[phone]", data.phone);
  formData.append("user[address]", data.address);
  formData.append("user[name]", data.fullName);
  formData.append("reservation[pickup_date]", data.pikcupDate);
  formData.append("reservation[drop_date]", data.dropDate);
  formData.append("reservation[car_id]", data.carID);
  formData.append("reservation[drop_location]", data.dropLocation);
  formData.append("reservation[pickup_location]", data.pikcupLocation);
  formData.append("image_passport", data.imagePassport);
  formData.append("image_id", data.imageID);
  formData.append("image_license", data.imageDriver);
  formData.append("price", data.price);
  formData.append("system_reservation", "AGS Rent - Website");
  return apiRequest(
    "POST",
    "reservation-client",
    formData,
    "",
    "multipart/form-data"
  );
};

export const locations = () => {
  return apiRequest("GET", "locations/all", "", "", "application/json");
};
