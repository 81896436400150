import React, { useContext } from "react";

export interface BookingContextType {
  getFreeList: (pickup_date: any, drop_date: any) => void;
  getLocations: () => void;
  createBooking: (bookingData: {
    address: any;
    pikcupLocation: number;
    imageID: any;
    fullName: any;
    dropLocation: number;
    dropDate: any;
    carID: number;
    pikcupDate: any;
    imageDriver: any;
    phone: any;
    price: number;
    imagePassport: any;
    email: any;
  }) => void;
  cars: any;
  carsLoading: boolean;
  locations: any;
}

const defaultBookingContext: BookingContextType = {
  cars: [],
  getFreeList: () => {},
  carsLoading: false,
  createBooking: () => {},
  locations: [],
  getLocations: () => {},
};

export const BookingContext = React.createContext<BookingContextType>(
  defaultBookingContext
);

export function useBookingContext() {
  return useContext(BookingContext);
}
