import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "reactstrap";

import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";

import "./MainNavigation.scss";

export const MainNavigation = () => {
  const authCtx = useAuthContext();

  return (
    <div className="MainNavigation">
      <ul>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/cars">Explore Vehicles</NavLink>
        </li>
        <li>
          <NavLink to="/about">About</NavLink>
        </li>
        <li>
          <NavLink to="/contact">Contact</NavLink>
        </li>
        <li className="MainNavigation__auth">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>
          {authCtx.isAuthenticated ? (
            <>
              <NavLink to="/profile">Profile</NavLink>
              <Button className="logout" onClick={() => authCtx.logout()}>
                Logout
              </Button>
            </>
          ) : (
            <NavLink to="/login">LOGIN or REGISTER</NavLink>
          )}
        </li>
      </ul>
    </div>
  );
};
