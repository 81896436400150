import React, { useEffect } from "react";
import { Container, Row } from "reactstrap";

import { useBookingContext } from "../../lib/context/BookingContext/BookingContext";
import { PageBanner } from "../../components/PageBanner/PageBanner";
import { CarItemLoader } from "../../components/CarItem/CarItemLoader";
import {
  getBookingLocalValues,
  setBookingLocalValue,
} from "../../lib/helpers/bookingLocalStorage";
import { CarItem } from "../../components/CarItem/CarItem";
import { CarItemTypes } from "../../types/CarItemTypes";

import Banner from "../../assets/images/page-banner-01.jpg";

import "./Reservation.scss";
import moment from "moment";

export const Reservation = () => {
  const bookingCtx = useBookingContext();
  const offerData = bookingCtx.cars?.response;
  const localBookingData = getBookingLocalValues();

  useEffect(() => {
    const localPickupDateTime = moment(
      `${localBookingData.selectedPickupDate}` +
        ` ${localBookingData.selectedPickupTime}`,
      "DD/MM/YYYY hh:mm:ss"
    ).format("YYYY-MM-DD hh:mm");

    const localDropDateTime = moment(
      `${localBookingData.selectedDropDate}` +
        ` ${localBookingData.selectedDropTime}`,
      "DD/MM/YYYY hh:mm:ss"
    ).format("YYYY-MM-DD hh:mm");

    bookingCtx.getFreeList(localPickupDateTime, localDropDateTime);
    const clearOfferData = {
      selectedOffer: null,
    };
    setBookingLocalValue(clearOfferData);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="Reservation">
      <PageBanner
        headline="Book you trip to Kosovo"
        paragraph="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
        image={Banner}
      />
      <div className="Reservation__container">
        <Container>
          <Row>
            <div className="Reservation__head">
              <h2>You have searched</h2>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
            </div>
            <div className="Reservation__list">
              {bookingCtx.carsLoading ? (
                <>
                  <CarItemLoader />
                  <CarItemLoader />
                  <CarItemLoader />
                </>
              ) : (
                <>
                  {offerData?.map((item: CarItemTypes, index: number) => {
                    return <CarItem key={`${item.id}-${index}`} data={item} />;
                  })}
                </>
              )}
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};
