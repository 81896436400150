import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Label } from "reactstrap";
import { DateRangePicker } from "react-nice-dates";
import { enGB } from "date-fns/locale";
import moment from "moment";

import { useBookingContext } from "../../lib/context/BookingContext/BookingContext";
import {
  getBookingLocalValues,
  setBookingLocalValue,
} from "../../lib/helpers/bookingLocalStorage";
import { LocationTypes } from "../../types/LocationTypes";

import "react-nice-dates/build/style.css";
import "./Search.scss";

export const Search = () => {
  const localBookingData = getBookingLocalValues();
  const bookingCtx = useBookingContext();
  const history = useHistory();

  const [returnLocation, setReturnLocation] = useState<boolean>(false);
  const [pickupLocations, setPickupLocations] = useState<number>(
    localBookingData.selectedPickupLocation
  );
  const [dropLocations, setDropLocations] = useState<number>(
    localBookingData.selectedDropLocation
  );

  const locationsData = {
    selectedPickupLocation: pickupLocations,
    selectedDropLocation: pickupLocations,
  };

  if (returnLocation) {
    setBookingLocalValue({ selectedPickupLocation: pickupLocations });
    setBookingLocalValue({ selectedDropLocation: dropLocations });
  } else {
    setBookingLocalValue(locationsData);
  }

  const hangleChangeLocation = () => {
    if (returnLocation) {
      setReturnLocation(false);
      setBookingLocalValue({ selectedPickupLocation: pickupLocations });
      setBookingLocalValue({ selectedDropLocation: dropLocations });
    } else {
      setReturnLocation(true);
      setBookingLocalValue(locationsData);
    }
  };

  const allLocations = bookingCtx.locations.response;

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<any>(moment().subtract(1, "d"));

  const setLocalPickupDate = moment(startDate).format("DD/MM/YYYY");
  const setLocalDropDate = moment(endDate).format("DD/MM/YYYY");

  const locaData = {
    selectedPickupDate: setLocalPickupDate,
    selectedDropDate: setLocalDropDate,
  };
  setBookingLocalValue(locaData);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const clearOfferData = {
      selectedOffer: null,
    };
    setBookingLocalValue(clearOfferData);
    history.push("/reservation/freelist");
  };
  return (
    <div className="Search">
      <Form onSubmit={handleSubmit}>
        <div className="Search__Wrapper">
          <div className="Search__Locations">
            <Label
              htmlFor="pickup_location"
              className="Search__Locations__Label"
            >
              Pickup & Drop Location
            </Label>
            <div className="Search__LocationInputs">
              <Input
                type="select"
                name="pickup_location"
                id="pickup_location"
                value={localBookingData.selectedPickupLocation}
                onChange={(e: any) => setPickupLocations(e.target.value)}
              >
                {allLocations &&
                  allLocations.map((item: LocationTypes, key: number) => {
                    return (
                      <option value={item.id} key={key}>
                        {item.city}
                      </option>
                    );
                  })}
              </Input>
              {returnLocation && (
                <Input
                  type="select"
                  value={localBookingData.selectedDropLocation}
                  onChange={(e: any) => setDropLocations(e.target.value)}
                >
                  {allLocations &&
                    allLocations.map((item: LocationTypes, key: number) => {
                      return (
                        <option value={item.id} key={key}>
                          {item.city}
                        </option>
                      );
                    })}
                </Input>
              )}
            </div>
            <Label className="Search__Locations__Diff">
              <Input type="checkbox" onChange={hangleChangeLocation} />
              Different Return Location
            </Label>
          </div>
          <div className="Search__DateTime">
            <Label
              htmlFor="pickup_location"
              className="Search__Locations__Label"
            >
              Pickup & Drop Date&Time
            </Label>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={(e: any) => setStartDate(e)}
              onEndDateChange={(e: any) => setEndDate(e)}
              minimumDate={startDate}
              minimumLength={1}
              locale={enGB}
            >
              {({ startDateInputProps, endDateInputProps }) => (
                <div className="date-range">
                  <input
                    className={"input"}
                    {...startDateInputProps}
                    placeholder="Start date"
                  />
                  <select name="time" id="time">
                    <option value="">00:00</option>
                  </select>
                  <span className="date-range_arrow" />
                  <input
                    className={"input"}
                    {...endDateInputProps}
                    placeholder="End date"
                  />
                  <select name="time" id="time">
                    <option value="">00:00</option>
                  </select>
                </div>
              )}
            </DateRangePicker>
          </div>
          <button type="submit">Search</button>
        </div>
      </Form>
    </div>
  );
};
