import React, { useContext } from "react";
import { RegisterTypes, UserTypes } from "../../../types/UserTypes";

export interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  // isLoggingIn: boolean;
  // isRegistering: boolean;
  userView?: UserTypes;
  error?: string | { [key: string]: string };
  loginError?: string | { [key: string]: string };
  registerError?: string;
  checkAuthentication: () => void;
  login: (email: string, password: string, redirect?: boolean) => void;
  logout: (redirect?: string | false) => void;
  register: (input: RegisterTypes, noRedirect?: boolean) => void;
  updateUser: (values: UserTypes) => void;
  // forgotPassword: (email: string) => Promise<any>;
  // resetPassword: (input: ResetPasswordTypes) => Promise<any>;
}

const AuthContextValues: AuthContextType = {
  isAuthenticated: false,
  isLoading: false,
  // isLoggingIn: false,
  // isRegistering: false,
  userView: undefined,
  error: undefined,
  loginError: undefined,
  registerError: undefined,
  checkAuthentication: () => {},
  login: () => {},
  logout: () => {},
  register: () => {},
  updateUser: () => {},
  // forgotPassword: () => new Promise((resolve) => resolve()),
  // resetPassword: () => new Promise((resolve) => resolve()),
};

export const AuthContext = React.createContext<AuthContextType>(
  AuthContextValues
);

export const useAuthContext = () => useContext(AuthContext);
