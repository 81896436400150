import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as AGSLogo } from "./../../assets/images/agsrent-logo.svg";

import "./Logo.scss";

interface Props {
  noPath?: boolean;
}

export const Logo = (props: Props) => {
  return (
    <div className="Logo">
      {props.noPath ? (
        <AGSLogo />
      ) : (
        <NavLink to="/">
          <AGSLogo />
        </NavLink>
      )}
    </div>
  );
};
