export type LocalStorageKeys = "authToken";

export interface LocalStorageValues {
  authToken: string;
}

export const parseLocalStorageValues = () => {
  const localItems = localStorage.getItem("USER");
  return !localItems ? {} : JSON.parse(localItems);
};

export const getLocalValues = () => {
  const localValues = parseLocalStorageValues();

  return {
    authToken: localValues?.authToken,
  };
};

export const setLocalValue = (
  key: LocalStorageKeys,
  value: string | number[]
) => {
  const localValues = parseLocalStorageValues();

  const newValue = {
    ...localValues,
    [key]: value,
  };

  localStorage.setItem("USER", JSON.stringify(newValue));
};

export const deleteLocalValue = (key: LocalStorageKeys) => {
  const localValues = parseLocalStorageValues();

  if (localValues[key]) {
    delete localValues[key];
  }

  if (localValues) {
    localStorage.setItem("USER", JSON.stringify(localValues));
  } else {
    localStorage.removeItem("USER");
  }
};
