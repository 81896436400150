import React from "react";
import {
  FormGroup,
  Label,
  Input,
  Form,
  FormFeedback,
  Button,
} from "reactstrap";
import { BookingFormik } from "../../lib/formik/useBookingForkmik";

interface Props {
  loading?: boolean;
  formik: BookingFormik;
}

export const BookingForm = (props: Props) => {
  const { formik } = props;
  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup className="form-group">
        <Label for="bookingFullName">Full Name</Label>
        <Input
          type="text"
          id="bookingFullName"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.bookingFullName}
          invalid={
            !!formik.errors.bookingFullName && formik.touched.bookingFullName
          }
        />
        <FormFeedback>{formik.errors.bookingFullName}</FormFeedback>
      </FormGroup>
      <FormGroup className="form-group">
        <Label for="bookingEmail">Email</Label>
        <Input
          type="text"
          id="bookingEmail"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.bookingEmail}
          invalid={!!formik.errors.bookingEmail && formik.touched.bookingEmail}
        />
        <FormFeedback>{formik.errors.bookingEmail}</FormFeedback>
      </FormGroup>
      <FormGroup className="form-group">
        <Label for="bookingPhone">Phone</Label>
        <Input
          type="text"
          id="bookingPhone"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.bookingPhone}
          invalid={!!formik.errors.bookingPhone && formik.touched.bookingPhone}
        />
        <FormFeedback>{formik.errors.bookingPhone}</FormFeedback>
      </FormGroup>
      <FormGroup className="form-group">
        <Label for="bookingAddress">Address</Label>
        <Input
          type="text"
          id="bookingAddress"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.bookingAddress}
          invalid={
            !!formik.errors.bookingAddress && formik.touched.bookingAddress
          }
        />
        <FormFeedback>{formik.errors.bookingAddress}</FormFeedback>
      </FormGroup>
      <FormGroup className="form-group">
        <Label for="bookingImageID">Image ID</Label>
        <Input
          type="file"
          id="bookingImageID"
          className="form-control"
          onChange={(event: any) => {
            formik.setFieldValue(
              "bookingImageID",
              event.currentTarget.files[0]
            );
          }}
          invalid={
            !!formik.errors.bookingImageID && formik.touched.bookingImageID
          }
        />
        <FormFeedback>{formik.errors.bookingImageID}</FormFeedback>
      </FormGroup>
      <FormGroup className="form-group">
        <Label for="bookingImagePassport">Image Passport</Label>
        <Input
          type="file"
          id="bookingImagePassport"
          className="form-control"
          onChange={(event: any) => {
            formik.setFieldValue(
              "bookingImagePassport",
              event.currentTarget.files[0]
            );
          }}
          invalid={
            !!formik.errors.bookingImagePassport &&
            formik.touched.bookingImagePassport
          }
        />
        <FormFeedback>{formik.errors.bookingImagePassport}</FormFeedback>
      </FormGroup>
      <FormGroup className="form-group">
        <Label for="bookingImageLicense">Image License</Label>
        <Input
          type="file"
          id="bookingImageLicense"
          className="form-control"
          onChange={(event: any) => {
            formik.setFieldValue(
              "bookingImageLicense",
              event.currentTarget.files[0]
            );
          }}
          invalid={
            !!formik.errors.bookingImageLicense &&
            formik.touched.bookingImageLicense
          }
        />
        <FormFeedback>{formik.errors.bookingImageLicense}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Button
          type="submit"
          disabled={props.loading}
          onClick={() => props.formik.submitForm()}
          className="RegisterForm__btn w-100-mobile"
        >
          {props.loading ? "Registering" : "Register"}
        </Button>
      </FormGroup>
    </Form>
  );
};
