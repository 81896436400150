import { apiRequest } from "./Api";
import { getLocalValues } from "../lib/helpers/localStorage";

const localValues = getLocalValues();

export const login = (email, password) => {
  return apiRequest(
    "POST",
    "login",
    {
      email: email,
      password: password,
    },
    "",
    "application/json"
  );
};

export const userView = () => {
  return apiRequest(
    "GET",
    "user",
    "",
    localValues.authToken,
    "application/json"
  );
};

export const register = (name, email, password, confirm, phone, address) => {
  return apiRequest(
    "POST",
    "register",
    {
      name: name,
      email: email,
      password: password,
      password_confirmation: confirm,
      phone: phone,
      address: address,
      role: "client",
    },
    "",
    "application/json"
  );
};
