import React, { useEffect } from "react";
import { Input, Form, FormGroup, FormFeedback } from "reactstrap";
import { NavLink } from "react-router-dom";

import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { LoginFormik } from "../../lib/formik/useLoginFormik";

import "./Login.scss";

interface Props {
  isLoading?: boolean;
  formik: LoginFormik;
}

export const LoginForm = (props: Props) => {
  const authCtx = useAuthContext();

  const loginError =
    authCtx.loginError &&
    typeof authCtx.loginError === "string" &&
    (authCtx.loginError.includes("invalid_email") ||
      authCtx?.loginError.includes("incorrect_password"));

  useEffect(() => {
    if (loginError) {
      // notificationCtx.danger(t(errors.wrongEmailOrPassword), true);
    }
  }, [loginError]); // eslint-disable-line react-hooks/exhaustive-deps

  const { isLoading, formik } = props;

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div>{loginError}</div>
      <FormGroup>
        <Input
          type="email"
          name="email"
          className="white"
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder="Email"
          invalid={!!formik.errors.email && formik.touched.email}
        />
        <FormFeedback>Email Address is required</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          name="password"
          type="password"
          className="white"
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder="Password"
          invalid={!!formik.errors.password && formik.touched.password}
        />
        <FormFeedback>Password is required</FormFeedback>
      </FormGroup>

      <button
        type="submit"
        className="Login__form--btn mx-auto w-100-mobile"
        onClick={() => props.formik.submitForm()}
      >
        {isLoading ? "Is logging in" : "Login"}
      </button>

      <NavLink to="/forgot-password" className="forgot-btn">
        Forgot Password
      </NavLink>
    </Form>
  );
};
