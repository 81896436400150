import { useFormik, FormikValues, FormikHelpers } from "formik";
import * as Yup from "yup";
import { BookingFields } from "../../pages/UserDetails/UserDetails";

interface UseBookingFormOptions {
  initialValues?: BookingFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<BookingFields>
  ) => Promise<any>;
}

export const useBookingFormik = (options: UseBookingFormOptions) => {
  const BookingSchema = Yup.object().shape({
    bookingFullName: Yup.string().required("Full name is required"),
    bookingEmail: Yup.string()
      .email("Email is not valid")
      .required("Email is required"),
    bookingPhone: Yup.string().required("Phone is required"),
    bookingAddress: Yup.string().required("Address is required"),
    bookingImageID: Yup.mixed().required("Image ID is required"),
    bookingImagePassport: Yup.mixed().required("Image Passport is required"),
    bookingImageLicense: Yup.mixed().required("Image License is required"),
  });

  return useFormik({
    initialValues: {
      bookingFullName: "",
      bookingEmail: "",
      bookingPhone: "",
      bookingAddress: "",
      bookingImageID: "",
      bookingImagePassport: "",
      bookingImageLicense: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: BookingSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type BookingFormik = ReturnType<typeof useBookingFormik>;
