import Axios from "axios";

export const apiRequest = async (method, path, data, token, contentType) => {
  let config = {
    url: process.env.REACT_APP_API_URL + `/${path}`,
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": contentType,
      Authorization: `Bearer ${token}`,
    },
  };
  if (method === "GET") {
    config["params"] = data;
  } else {
    config["data"] = data;
  }
  const res = await Axios.request(config);
  return res.data;
};

export * from "./Auth";
export * from "./Cars";
export * from "./Booking";
