import React from "react";

import "./PageBanner.scss";
import { Container, Row } from "reactstrap";

interface Props {
  headline?: string;
  paragraph?: string;
  image?: string;
}

export const PageBanner = (props: Props) => {
  return (
    <div className="PageBanner">
      <span className="PageBanner__image">
        <img src={props.image} alt={props.headline} />
      </span>

      <div className="PageBanner__content">
        <Container>
          <Row>
            <h1>{props.headline}</h1>
            <p>{props.paragraph}</p>
          </Row>
        </Container>
      </div>
    </div>
  );
};
