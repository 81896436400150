import { useFormik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";
import { LoginFields } from "../../pages/Login/Login";

interface UseLoginFormOptions {
  initialValues?: LoginFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<LoginFields>
  ) => Promise<any>;
}

export const useLoginFormik = (opts: UseLoginFormOptions) => {

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Email"),
    password: Yup.string().required("Password"),
  });

  return useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: LoginSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};

export type LoginFormik = ReturnType<typeof useLoginFormik>;
