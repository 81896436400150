import React, { useEffect, useState } from "react";
import moment from "moment";

import { BookingContext, BookingContextType } from "./BookingContext";
import * as API from "../../../Api/Api";
import {
  getBookingLocalValues,
  setBookingLocalValue,
} from "../../helpers/bookingLocalStorage";
import { CarItemTypes } from "../../../types/CarItemTypes";
import { setLocalValue } from "../../helpers/localStorage";
import { LocationTypes } from "../../../types/LocationTypes";

interface Props {
  offerData?: CarItemTypes;
  children: React.ReactNode | null;
}

export const BookingContextProvider = (props: Props) => {
  const [cars, setCars] = useState<BookingContextType[]>([]);
  const [locations, setLocations] = useState<LocationTypes[]>([]);
  const [carsLoading, setCarsLoading] = useState(false);
  const localBookingData = getBookingLocalValues();

  //date and time
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const todayDate = new Date();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const oneDay = new Date(todayDate.getTime() + 24 * 60 * 60 * 1000);
  const todayDateFormated = moment(todayDate).format("DD/MM/YYYY");
  const oneDayFormated = moment(oneDay).format("DD/MM/YYYY");
  const todayTimeFormated = moment(todayDate.getTime()).format("HH:mm:ss");
  const oneDayTimeFormated = moment(oneDay.getTime()).format("HH:mm:ss");

  useEffect(() => {
    const locaData = {
      selectedPickupDate: todayDateFormated,
      selectedDropDate: oneDayFormated,
      selectedPickupTime: todayTimeFormated,
      selectedDropTime: oneDayTimeFormated,
      selectedPickupLocation: undefined,
      selectedDropLocation: undefined,
      selectedOffer: "",
      pickupAsReturn: true,
    };
    if (
      !localBookingData.selectedPickupDate &&
      !localBookingData.selectedDropDate &&
      !localBookingData.selectedPickupTime &&
      !localBookingData.selectedDropTime
    ) {
      setBookingLocalValue(locaData);
    }

    //get locations
    getLocations();
    // eslint-disable-next-line
  }, []);

  const getFreeList = (pickup_date: any, drop_date: any) => {
    setCarsLoading(true);

    API.getCars(pickup_date, drop_date).then((res) => {
      setCars(res);

      if (res) {
        setCarsLoading(false);
      }
    });
  };

  const createBooking = (bookingData: {
    address: any;
    pikcupLocation: number;
    imageID: any;
    fullName: any;
    dropLocation: number;
    dropDate: number;
    carID: number;
    pikcupDate: number;
    imageDriver: any;
    phone: any;
    price: number;
    imagePassport: any;
    email: any;
  }) => {
    API.booking(bookingData).then((res) => {
      if (res) {
        setLocalValue("authToken", res.response.token);
      }
    });
  };

  const getLocations = () => {
    API.locations().then((res) => {
      setLocations(res);
    });
  };

  const context: BookingContextType = {
    cars,
    carsLoading,
    locations,
    getLocations,
    getFreeList,
    createBooking,
  };
  return (
    <BookingContext.Provider value={context}>
      {props.children}
    </BookingContext.Provider>
  );
};
