import React, { useEffect, useState } from "react";
import { useRouter } from "../../hooks/useRouter";

import { isTokenExpired } from "../../helpers/isTokenExpired";
import { AuthContext, AuthContextType } from "./AuthContext";
import * as API from "../../../Api/Api";

import { getLocalValues, setLocalValue } from "../../helpers/localStorage";

import { RegisterTypes, UserTypes } from "../../../types/UserTypes";

interface AuthContextProviderProps {
  children: React.ReactNode | null;
}

interface AuthContextProviderState {
  loading: boolean;
  userView: UserTypes | undefined;
  error?: string | { [key: string]: string };
  loginError?: string | { [key: string]: string };
  registerError?: string;
}

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const router = useRouter();
  const localValues = getLocalValues();

  const [state, setState] = useState<AuthContextProviderState>({
    loading: true,
    userView: undefined,
    error: undefined,
    loginError: undefined,
    registerError: undefined,
  });

  //On route change: reset errors
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      error: undefined,
      loginError: undefined,
      registerError: undefined,
    }));
  }, [router.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    checkAuthentication();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkAuthentication = async () => {
    if (!localValues.authToken || isTokenExpired(localValues.authToken)) {
      setState({
        ...state,
        userView: undefined,
        loading: false,
      });
      return;
    }

    try {
      const res = await API.userView();

      if (res.errors) {
        setState({
          ...state,
          loading: false,
          error: res.errors.toString(),
        });
      }

      if (res) {
        const user = res;

        setState({
          ...state,
          userView: {
            id: user.id,
            email: user.email,
            fullName: user.fullName,
            phone: user.phone,
            address: user.address,
          },
          error: undefined,
          loading: false,
        });
      }
    } catch (err) {
      setState({
        ...state,
        error: err.toString(),
        userView: undefined,
        loading: false,
      });
    }
  };

  const updateUser = (values: UserTypes) => {
    setState({
      ...state,
      userView: {
        ...values,
      },
    });
  };

  const login = async (
    email: string,
    password: string,
    redirect: boolean = true
  ) => {
    try {
      const res = await API.login(email, password);

      if (!res) {
        return;
      }
      const user = res.userView;

      setLocalValue("authToken", res.token);

      setState({
        ...state,
        loginError: undefined,
        userView: {
          id: user.id,
          email: user.email,
          fullName: user.fullName,
          phone: user.phone,
          address: user.address,
        },
      });

      const hasRedirectParam = router.location.search.includes("redirect");
      const returnURL =
        redirect && hasRedirectParam
          ? decodeURIComponent(router.location.search.split("redirect=")[1])
          : "/";

      router.history.push(returnURL);
    } catch (e) {
      setState({
        ...state,
        loginError: e,
      });
    }
  };

  const register = async (input: RegisterTypes) => {
    try {
      const res = await API.register(
        input.fullName,
        input.email,
        input.password,
        input.password_confirmation,
        input.phone,
        input.address
      );

      if (res) {
        const user = res;

        setState({
          ...state,
          registerError: undefined,
          userView: {
            id: user.id,
            email: user.email,
            fullName: user.fullName,
            phone: user.phone,
            address: user.address,
          },
        });

        setLocalValue("authToken", res.token);

        const hasRedirectParam = router.location.search.includes("redirect");
        const returnURL = hasRedirectParam
          ? decodeURIComponent(router.location.search.split("redirect=")[1])
          : "/";

        router.history.push(returnURL);
      }
    } catch (e) {
      setState({
        ...state,
        registerError: e,
      });
    }
  };

  // const forgotPassword = (email: string) => {
  //   return forgotPasswordMutation({
  //     variables: {
  //       email: email,
  //     },
  //   });
  // };
  //
  // const resetPassword = (input: ResetPasswordTypes) => {
  //   return resetPasswordMutation({
  //     variables: {
  //       key: input.key,
  //       email: input.email,
  //       password: input.password,
  //     },
  //   });
  // };

  const logout = async () => {
    localStorage.removeItem("USER");
    setState({
      ...state,
      userView: undefined,
    });
    // await client.cache.reset();
    router.history.push("/");
  };

  const context: AuthContextType = {
    isAuthenticated: state.userView !== undefined,
    isLoading: state.loading,
    // isLoggingIn: isLoggingIn,
    // isRegistering: isRegistering,
    error: state.error,
    loginError: state.loginError,
    registerError: state.registerError,
    userView: state.userView,
    updateUser,
    logout: logout,
    checkAuthentication,
    login: login,
    register: register,
    // forgotPassword: forgotPassword,
    // resetPassword: resetPassword,
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};
