import React from "react";
import ContentLoader from "react-content-loader";

export const CarItemLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={400}
      height={460}
      viewBox="0 0 400 460"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="5" y="2" rx="2" ry="2" width="391" height="205" />
      <rect x="6" y="218" rx="0" ry="0" width="329" height="14" />
      <rect x="7" y="243" rx="0" ry="0" width="329" height="14" />
      <rect x="8" y="271" rx="0" ry="0" width="85" height="26" />
      <rect x="103" y="271" rx="0" ry="0" width="85" height="26" />
      <rect x="197" y="271" rx="0" ry="0" width="85" height="26" />
      <rect x="292" y="271" rx="0" ry="0" width="85" height="26" />
    </ContentLoader>
  );
};
