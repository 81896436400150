import { apiRequest } from "./Api";

export const getCars = (pickup_date, drop_date) => {
  return apiRequest(
    "GET",
    "cars/free/list",
    {
      pickup_date: pickup_date,
      drop_date: drop_date,
    },
    "",
    "application/json"
  );
};
