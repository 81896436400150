import React, { useEffect } from "react";

import { useRegisterFormik } from "../../lib/formik/useRegisterForkmik";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";

import "./Register.scss";
import { RegisterForm } from "./RegisterForm";
import Image from "../../assets/images/image-01.jpg";

export interface RegisterFields {
  registerFullName: string;
  registerEmail: string;
  registerPhone: string;
  registerAddress: string;
  registerPassword: string;
  registerConfirmPassword: string;
  registerTermAndConditions: boolean;
}

export const Register = () => {
  const authCtx = useAuthContext();

  const formik = useRegisterFormik({
    onSubmit: async (values) => {
      authCtx.register({
        fullName: values.registerFullName,
        address: values.registerAddress,
        password: values.registerPassword,
        password_confirmation: values.registerConfirmPassword,
        phone: values.registerPhone,
        email: values.registerEmail,
        termAndConditions: values.registerTermAndConditions,
      });
    },
  });

  useEffect(() => {
    formik.setErrors({});
    formik.setTouched({});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="Register Home">
      <div className="Home__left-side">
        <span className="Home__left-side-image">
          <img src={Image} alt="" />
        </span>
        <div className="Home__left-side-content">
          <strong>
            Enjoy
            <br />
            the journey
            <br />
            with your family!
          </strong>
          <p>Motor cars rental</p>
        </div>
      </div>
      <div className="Home__right-side">
        <h1>Register</h1>
        <div className="register-wrapper">
          <RegisterForm formik={formik} />
        </div>
      </div>
    </div>
  );
};
