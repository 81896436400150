import React from "react";
import Image from "./../../assets/images/image-01.jpg";

import "./Home.scss";
import { Search } from "../../components/Search/Search";

export const Home = () => {
  return (
    <div className="Home">
      <div className="Home__left-side">
        <span className="Home__left-side-image">
          <img src={Image} alt="" />
        </span>
        <div className="Home__left-side-content">
          <strong>
            Enjoy
            <br />
            the journey
            <br />
            with your family!
          </strong>
          <p>Motor cars rental</p>
        </div>
      </div>
      <div className="Home__right-side">
        <h1>Find your car</h1>
        <Search />
      </div>
    </div>
  );
};
