export type BookingLocalStorageKeys =
  | "selectedPickupDate"
  | "selectedDropDate"
  | "selectedPickupTime"
  | "selectedDropTime"
  | "selectedPickupLocation"
  | "selectedDropLocation"
  | "selectedOffer"
  | "pickupAsReturn";

export interface BookingLocalStorageValues {
  selectedPickupDate?: string;
  selectedDropDate?: string;
  selectedPickupTime?: string;
  selectedDropTime?: string;
  selectedPickupLocation?: number;
  selectedDropLocation?: number;
  selectedOffer?: {} | null;
  pickupAsReturn?: boolean;
}

export const parseBookingLocalStorageValues = () => {
  const localItems = localStorage.getItem("bookingData");
  return !localItems ? {} : JSON.parse(localItems);
};

export const getBookingLocalValues = () => {
  const bookingLocalValues = parseBookingLocalStorageValues();

  return {
    selectedPickupDate: bookingLocalValues?.selectedPickupDate,
    selectedDropDate: bookingLocalValues?.selectedDropDate,
    selectedPickupTime: bookingLocalValues?.selectedPickupTime,
    selectedDropTime: bookingLocalValues?.selectedDropTime,
    selectedPickupLocation: bookingLocalValues?.selectedPickupLocation,
    selectedDropLocation: bookingLocalValues?.selectedDropLocation,
    selectedOffer: bookingLocalValues?.selectedOffer,
    pickupAsReturn: bookingLocalValues?.pickupAsReturn,
  };
};

export const setBookingLocalValue = (data: BookingLocalStorageValues) => {
  const bookingLocalValues = parseBookingLocalStorageValues();

  const newValue = {
    ...bookingLocalValues,
    ...data,
  };

  localStorage.setItem("bookingData", JSON.stringify(newValue));
};

export const deleteBookingLocalValue = (key: BookingLocalStorageKeys) => {
  const bookingLocalValues = parseBookingLocalStorageValues();

  if (bookingLocalValues[key]) {
    delete bookingLocalValues[key];
  }

  if (bookingLocalValues) {
    localStorage.setItem("bookingData", JSON.stringify(bookingLocalValues));
  } else {
    localStorage.removeItem("bookingData");
  }
};
