import React from "react";
import { Redirect } from "react-router-dom";

import { useLoginFormik } from "../../lib/formik/useLoginFormik";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";

import { LoginForm } from "./LoginForm";
import Image from "../../assets/images/image-01.jpg";
import "./Login.scss";
import { NavLink } from "react-router-dom";

export interface LoginFields {
  email: string;
  password: string;
}

export const Login = () => {
  const authCtx = useAuthContext();
  const formik = useLoginFormik({
    onSubmit: async (values) => authCtx.login(values.email, values.password),
  });

  if (authCtx.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="Login Home">
      <div className="Home__left-side">
        <span className="Home__left-side-image">
          <img src={Image} alt="" />
        </span>
        <div className="Home__left-side-content">
          <strong>
            Enjoy
            <br />
            the journey
            <br />
            with your family!
          </strong>
          <p>Motor cars rental</p>
        </div>
      </div>
      <div className="Home__right-side">
        <h1>Login</h1>
        <LoginForm formik={formik} />
        <NavLink to="/register">Register</NavLink>
      </div>
    </div>
  );
};
